body {
    font-family: sans-serif;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

#canvas {
    border: 1px solid rgb(0, 0, 0);
    background: black;
    cursor: none;
    width: 100%;
}